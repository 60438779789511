export const Mail_Vars = {
    fb_link: "https://www.facebook.com/",
    tw_link: "https://twitter.com/",
    sitename: "XO Property",
    contact_email: "info@xoproperty.ae",
    company_phone: "(04) 433 0046",
    available_time: "9am - 5pm ,",
    available_days: " Monday - Friday",
    address: "Office 507, Control Tower, Motor City, Dubai",
    primary_color: "#000000"
}


export const Site_Vars = {
    default_currency: "AED "
}
export const OFF_PLAN = "new_development"